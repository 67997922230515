.hamburger-react-big {
	& > * > * {
		height: 6px !important;
	}
}

.hamburger-react-small {
	& > * > * {
		height: 4px !important;
	}
}
